import React, { useState } from 'react'

import { navigate } from 'gatsby'
import styled from 'styled-components'

import ConfirmationModal from 'components/shared/confirmation-modal'
import useMeetingMutateNoContext from 'context/meeting/use-mutate-no-context'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import Button from 'design-system/components/button'
import { SectionDivider } from 'design-system/components/section-divider'

export const SelectionCenterNextSteps = () => {
  const { meeting, isSummaryNotesSubmitted, refetchMeeting, clientName } =
    useSelectionCenterVisit()
  const { updateMeeting } = useMeetingMutateNoContext()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loadingNoShow, setLoadingNoShow] = useState(false)

  const meetingHasStarted =
    !!meeting?.startTime && new Date(meeting.startTime) < new Date()

  const canMarkNoShow = !meeting?.metadata?.customerNoShow && meetingHasStarted

  const handleNoShow = async () => {
    if (!meeting?.id) return
    setLoadingNoShow(true)

    await updateMeeting({
      variables: {
        where: {
          id: meeting.id,
        },
        data: {
          metadata: {
            ...meeting.metadata,
            customerNoShow: true,
          },
        },
      },
    })

    await refetchMeeting()
    setLoadingNoShow(false)
    setOpenConfirmation(false)
  }

  return (
    <Container>
      <SectionDivider title="Other Actions" />
      <div className="content">
        {canMarkNoShow && (
          <Button
            kind="solid"
            text="Customer No Show"
            color="gray"
            size="large"
            onClick={() => setOpenConfirmation(true)}
            loading={loadingNoShow}
          />
        )}
        <Button
          kind="solid"
          text="Submit notes"
          color="dark"
          size="large"
          onClick={() => navigate('summary-notes')}
          disabled={isSummaryNotesSubmitted}
        />
        <Button
          kind="solid"
          text="Book a call"
          color="dark"
          size="large"
          onClick={() => navigate('book-call')}
          disabled={meeting?.metadata?.selectionCenter?.isSCFollowupScheduled}
        />
      </div>
      <ConfirmationModal
        open={openConfirmation}
        onOpen={() => setOpenConfirmation(true)}
        onCancel={() => setOpenConfirmation(false)}
        onConfirm={handleNoShow}
        firstTitle='Are you sure you want to mark this meeting as "No Show"?'
        secondTitle={`Mark it only if you are sure that ${clientName} did not arrive to the meeting.`}
        loading={loadingNoShow}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;

  div.content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    width: 100%;
  }
`
