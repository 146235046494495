import { useLazyQuery } from '@apollo/client'

import {
  FIND_FIRST_MEETING,
  FindFirstMeetingPayload,
  FindFirstMeetingVariables,
} from 'queries/meeting'

export const useMeetingFirstLazy = () => {
  const [fetchMeeting, opts] = useLazyQuery<
    FindFirstMeetingPayload,
    FindFirstMeetingVariables
  >(FIND_FIRST_MEETING)

  return { fetchMeeting, ...opts }
}
