import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { NoteCard } from 'components/admin/project-notes/note-card'
import { SelectionCenterVisitLayout } from 'components/selection-center/visit'
import EstimateData from 'components/selection-center/visit/estimate'
import { Chip } from 'components/shared/chip'
import useAuth from 'context/auth/use'
import { parseDesignOptions, parseQuizAnswers } from 'context/prospect/utils'
import { getRoomTypeWithDescription } from 'context/room/utils'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { Drawer } from 'design-system/components/drawer'

const SelectionCenterVisitProjectBrief = (_: RouteComponentProps) => {
  const { formUserId } = useAuth()
  const { project, meeting, prospect, designerNotes, refetchVisitNotes } =
    useSelectionCenterVisit()
  const parsedInspirations = parseDesignOptions(prospect)
  const parsedQuizAnswers = parseQuizAnswers(prospect?.data?.quizAnswers ?? [])
  const inspirationAnswer = parsedInspirations?.inspirationGenerals.length
    ? parsedInspirations?.inspirationGenerals
    : parsedInspirations?.inspirationMoodboards

  const estimate = prospect?.data?.estimate ?? null

  return (
    <SelectionCenterVisitLayout
      backUrl={`/selection-centers/visit/${meeting?.id}`}
    >
      <div className="project-info">
        <h3 className="no-margin">Project Brief</h3>
      </div>
      <Grid>
        {!!designerNotes?.length && (
          <Section title="Designer Notes">
            {designerNotes.map((note) => (
              <NoteCard
                isSuperAdmin={false}
                formUserId={formUserId}
                note={note}
                refetch={refetchVisitNotes}
                key={note.id}
                showProjectData={false}
                isProjectNote
              />
            ))}
          </Section>
        )}

        {project?.rooms.length ? (
          <Section title="Rooms">
            <RoomsContainer>
              {project?.rooms.map((r) => (
                <Chip name={getRoomTypeWithDescription(r)} key={r.id} />
              ))}
            </RoomsContainer>
          </Section>
        ) : null}

        {parsedInspirations ? (
          <Section title="Inspiration Images">
            <Grid>
              <Grid.Row columns={2} style={{ rowGap: '28px' }}>
                {inspirationAnswer?.map((e, i) => (
                  <Grid.Column key={i}>
                    <Image
                      src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                        String(e.data?.imageUrl),
                      )}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Section>
        ) : null}

        {parsedQuizAnswers?.priorityAnswer.length ? (
          <Section title="Priorities">
            <Grid>
              <Grid.Row columns={2} style={{ rowGap: '28px' }}>
                {parsedQuizAnswers.priorityAnswer?.map((p, i) => (
                  <Grid.Column key={i} textAlign="center">
                    <Image
                      src={`${process.env.GATSBY_CDN_ROOT}/images/onboarding/priority/${p.image}`}
                      style={{ marginBottom: '16px' }}
                    />
                    <p>{p.value}</p>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Section>
        ) : null}

        {estimate ? (
          <Section title="Project Estimation">
            <Grid centered>
              <Grid.Row centered>
                <Grid.Column tablet={16} computer={8}>
                  <EstimateData />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Section>
        ) : null}
      </Grid>
    </SelectionCenterVisitLayout>
  )
}

const RoomsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  flex-wrap: wrap;
`

type SectionProps = {
  title: string
  children: React.ReactNode
}

const Section = ({ children, title }: SectionProps) => {
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Drawer
          title={title}
          allowOutsideClick
          content={children}
          defaultOpen
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default SelectionCenterVisitProjectBrief
