import React from 'react'

import { navigate } from 'gatsby'

import Button from 'design-system/components/button/button'
import HeaderContainer, {
  FormLogoItem,
} from 'design-system/containers/header/header'

type SelectionCenterHeaderProps = {
  actions?: React.ReactNode
  backButtonText?: string
  backUrl?: string
  disableBackButton?: boolean
  handleBack?: () => void
  showBackButton?: boolean
  sticky?: boolean
}

export const SelectionCenterHeader = ({
  actions,
  backButtonText = 'Back',
  backUrl = '/selection-centers',
  disableBackButton,
  handleBack,
  showBackButton,
  sticky,
}: SelectionCenterHeaderProps) => {
  const handleClick = () => {
    if (handleBack) {
      handleBack()
    } else if (history.length > 1 && window.history.state?.key && !backUrl) {
      navigate(-1)
    } else {
      navigate(backUrl, { replace: true })
    }
  }

  const backBtn = (
    <Button
      kind="text"
      color="gray"
      text={backButtonText}
      fontAwesomeIcon="arrow-left"
      iconAnimation="left"
      iconPosition="left"
      onClick={handleClick}
      disabled={disableBackButton}
      id="selection-center-back"
    />
  )

  return (
    <HeaderContainer
      leftItems={showBackButton || handleBack ? backBtn : <FormLogoItem />}
      rightItems={actions}
      sticky={sticky}
    />
  )
}
