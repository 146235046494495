import React from 'react'

import { navigate } from 'gatsby'
import styled from 'styled-components'

import { Meeting } from 'types/meeting'

import { SelectionCenterMeetingCard } from './meeting-card'

type SelectionCenterMeetingMultipleCardsProps = {
  meetings: Meeting[]
  isTomorrowMeeting?: boolean
  timeZone?: string
}

export const SelectionCenterMeetingMultipleCards = ({
  meetings,
  isTomorrowMeeting,
  timeZone,
}: SelectionCenterMeetingMultipleCardsProps) => {
  return (
    <StyledDiv>
      {meetings.length ? (
        meetings.map((meeting) => (
          <SelectionCenterMeetingCard
            meeting={meeting}
            key={meeting.id}
            onClick={() => {
              navigate(`/selection-centers/visit/${meeting.id}`)
            }}
            isTomorrowMeeting={isTomorrowMeeting}
            timeZone={timeZone}
          />
        ))
      ) : (
        <p className="gray no-margin">No meetings scheduled</p>
      )}
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > p {
    text-align: center;
  }
`
