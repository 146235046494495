import React from 'react'

import { Container } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Header from 'components/app/project/header/header-basic'
import { Breakpoint } from 'styles/app/system'

interface AppLayoutProps {
  children: React.ReactNode
  header?: React.ReactNode
  notificationBar?: React.ReactNode
  fullHeight?: boolean
}

export default ({
  children,
  notificationBar,
  header,
  fullHeight,
}: AppLayoutProps) => {
  return (
    <>
      {header || <Header />}
      {notificationBar}
      <AppContainer className="app-layout" $fullHeight={fullHeight}>
        {children}
      </AppContainer>
    </>
  )
}

const AppContainer = styled(Container)<{ $fullHeight?: boolean }>`
  padding-top: 100px;
  padding-bottom: 50px;
  min-height: unset;

  ${(props) =>
    props.$fullHeight &&
    css`
      height: 100vh;
    `};

  @media (min-width: 1350px) {
    width: 1280px !important;
  }

  @media ${Breakpoint.onlyMobile} {
    flex: auto;
    padding-bottom: 100px;
  }

  @media print {
    padding-top: 0px;
  }
`
