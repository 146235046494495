import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { SelectionCenterHeader } from 'components/selection-center/header'
import Loader from 'components/shared/loader'
import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import { useSelectionCenters } from 'context/selection-centers/provider'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import { MeetingTypeProject } from 'types/meeting'

const SelectionCenterAdminScheduleBooker = (_: RouteComponentProps) => {
  const { selectionCenter } = useSelectionCenters()
  const [loadingCalendly, setLoadingCalendly] = useState(true)
  const [scheduled, setScheduled] = useState(false)

  const handleEventScheduled = () => {
    setScheduled(true)
  }

  const handleEventTypeViewed = () => {
    setLoadingCalendly(false)
  }

  return (
    <AppLayout
      fullHeight
      header={
        <SelectionCenterHeader showBackButton backUrl="/selection-centers" />
      }
    >
      {(!selectionCenter || loadingCalendly) && <Loader flex />}
      {selectionCenter ? (
        <CalendlyInlineScheduler
          calendlyUrl={selectionCenter.data?.calendlyUrl ?? ''}
          onEventScheduled={handleEventScheduled}
          onEventTypeViewed={handleEventTypeViewed}
          type={MeetingTypeProject.SELECTION_CENTER}
          fullHeight
        />
      ) : null}

      {scheduled ? (
        <ButtonContainer>
          <Button
            kind="solid"
            text="Return to dashboard"
            onClick={() => navigate('/selection-centers')}
          />
        </ButtonContainer>
      ) : null}
    </AppLayout>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default SelectionCenterAdminScheduleBooker
