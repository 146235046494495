export const getSessionStorage = <TData extends Record<string, unknown>>(
  key: string,
): TData | null => {
  const value = sessionStorage.getItem(key)
  return value ? JSON.parse(value) : null
}

export const setSessionStorage = <TData extends Record<string, unknown>>(
  key: string,
  data?: TData | null,
) => {
  const value = JSON.stringify(data ?? {})
  sessionStorage.setItem(key, value)
}

export const setSessionStorageNoOverwrite = <
  TData extends Record<string, unknown>,
>(
  key: string,
  data: TData,
) => {
  const currentData = getSessionStorage<TData>(key)

  const newData = {
    ...currentData,
    ...data,
  }

  setSessionStorage(key, newData)
}
