import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Divider, Grid, SearchProps } from 'semantic-ui-react'

import * as SelectionUtils from 'components/app/project/room/selection/utils'
import NobiliaOptionSelectionCard from 'components/shared/catalog/nobilia-option/card'
import { getChipColor } from 'components/shared/chip'
import NobiliaOptionManyProvider from 'context/nobilia-option/provider-many'
import useNobiliaOptionMany from 'context/nobilia-option/use-many'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { Front, groupFronts } from 'context/selection-system/fronts'
import Button from 'design-system/components/button'
import { SelectionCardVariation } from 'design-system/components/selection-card'
import SelectionLayout from 'design-system/containers/selection-layout'
import * as NO from 'planner/objects/products/nobilia/options/definitions'
import { Breakpoint } from 'styles/app/system'
import { NobiliaOption } from 'types/nobilia-option'
import { catalogToQuery } from 'views/utils'

type FrontsBrowserProps = RouteComponentProps<{
  catalog: string
  frontsSelected?: Partial<NobiliaOption>[]
  hideScroll?: boolean
  onBack?: () => void
  onSave?: (item: Partial<NobiliaOption>) => void
}>

const SESSION_STORAGE_CATEGORY_KEY = 'selectionFrontsCategorySelected'

export const NobiliaOptionColumn = ({
  category,
  front,
  frontsSelected,
  onSave,
}: {
  category: string
  front: Front
  frontsSelected?: Partial<NobiliaOption>[]
  onSave?: (item: Partial<NobiliaOption>) => void
}) => {
  const { meeting } = useSelectionCenterVisit()
  return (
    <Grid.Column key={front.id}>
      <NobiliaOptionSelectionCard
        nobiliaOption={front}
        customNavigate
        onExpand={() => {
          navigate(
            `/selection-centers/visit/${meeting?.id}/catalog/${front.catalog}/nobilia-features/${front.featureNo}/nobilia-options/${front.id}`,
          )
          // So category persist when user clicks expand on a front card
          sessionStorage.setItem(SESSION_STORAGE_CATEGORY_KEY, category)
        }}
        selectionCardProps={
          onSave
            ? {
                expandAndSelect: true,
                onSelect: () => onSave(front),
                priceGroup: front.priceGroup,
                selected: frontsSelected?.some((s) => s.id === front.id),
              }
            : {
                expandOnly: true,
                priceGroup: front.priceGroup,
              }
        }
        selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
      />
    </Grid.Column>
  )
}

const FrontsBrowser = ({
  catalog,
  frontsSelected,
  onBack,
  onSave,
  hideScroll,
}: FrontsBrowserProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)

  const { fronts } = useNobiliaOptionMany()

  const [search, setSearch] = useState<{
    results: Front[] | null
    value: string
  }>({
    results: null,
    value: '',
  })

  const frontCategory = [
    'Matt',
    'Gloss',
    'Shaker',
    'WoodReproduction',
    'NaturalReproduction',
  ]
  const [category, setCategory] = useState<string>(() => {
    const savedValue = sessionStorage.getItem(SESSION_STORAGE_CATEGORY_KEY)
    sessionStorage.removeItem(SESSION_STORAGE_CATEGORY_KEY)
    return savedValue || 'Matt'
  })

  const { frontsPerCategory, frontsAll } = useMemo(
    () =>
      groupFronts({
        catalog: catalog ?? '',
        fronts,
      }),
    [fronts],
  )

  const handleSearchChange = (
    _e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: SearchProps,
  ) => {
    setSearch({
      results: !value
        ? null
        : frontsAll.filter((f) =>
            [f.optionKey, f.description, f.data?.displayName].some(
              (s) => s?.toLowerCase().includes(value.toLowerCase()),
            ),
          ),
      value: value as string,
    })
  }

  const renderColumns = () => {
    if (search.value && !search.results?.length) {
      return (
        <Grid.Column>
          <p>No results</p>
        </Grid.Column>
      )
    } else if (search.results) {
      return search.results.map((f) => (
        <NobiliaOptionColumn
          key={f.id}
          category={category}
          front={f}
          frontsSelected={frontsSelected}
          onSave={onSave}
        />
      ))
    } else
      return frontsPerCategory[category].groups.map((g, idx) => (
        <React.Fragment key={g.name}>
          {g.name ? (
            <SelectionUtils.CategoryColumn width={16}>
              {idx > 0 && <Divider hidden className="up-mobile" />}
              <p
                className="caption"
                style={{
                  borderBottom: '1px dashed #D6D3D1',
                  paddingBottom: '8px',
                }}
              >
                {g.name}
              </p>
            </SelectionUtils.CategoryColumn>
          ) : null}
          {g.fronts.map((f) => (
            <NobiliaOptionColumn
              key={f.id}
              category={category}
              front={f}
              frontsSelected={frontsSelected}
              onSave={onSave}
            />
          ))}
        </React.Fragment>
      ))
  }

  return (
    <SelectionLayout
      back={
        onBack && (
          <Button
            color="gray"
            fontAwesomeIcon="arrow-left"
            iconAnimation="left"
            iconPosition="left"
            kind="text"
            onClick={onBack}
            text={isMobile ? '' : 'Back'}
          />
        )
      }
      categories={frontCategory.map((c, key) => (
        <SelectionUtils.Category
          active={category === c}
          disabled={!!search.value}
          key={key}
          onClick={() => setCategory(c)}
          color={getChipColor(null, 1).tagColor}
          textColor={getChipColor(null).textColor}
        >
          <p>{frontsPerCategory[c].title}</p>
        </SelectionUtils.Category>
      ))}
      hideScroll={hideScroll}
      searchProps={{
        onClose: () => {
          setCategory('Matt')
          setSearch({
            results: null,
            value: '',
          })
        },
        onSearchChange: handleSearchChange,
        value: search.value,
      }}
      selection={
        frontsSelected?.length
          ? frontsSelected.map((f) => (
              <SelectionUtils.OptionSelected key={f.id}>
                <p className="no-margin small dark-gray">{f.description}</p>
                <span onClick={() => onSave?.(f)}>
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </span>
              </SelectionUtils.OptionSelected>
            ))
          : null
      }
      title="Fronts"
    >
      <Grid
        doubling
        columns={5}
        style={{ marginTop: isMobile ? '10px' : 'none' }}
      >
        <Grid.Row stretched className="row-gap">
          {renderColumns()}
        </Grid.Row>
      </Grid>
    </SelectionLayout>
  )
}

export default (props: FrontsBrowserProps) => {
  return (
    <NobiliaOptionManyProvider
      catalog={catalogToQuery(props.catalog ?? '')}
      defaultFilters={{
        catalog: catalogToQuery(props.catalog ?? ''),
        feature: {
          featureNo: NO.FRONT_COMBO,
        },
        sortBy: 'optionKey',
        sortDirection: 'ascending',
      }}
    >
      <FrontsBrowser {...props} />
    </NobiliaOptionManyProvider>
  )
}
