import { useLazyQuery } from '@apollo/client'

import {
  FIND_UNIQUE_USER,
  FindUniqueUserPayload,
  FindUniqueUserVariables,
} from 'queries/user'

const useUserUniqueLazy = () => {
  const [getUser, { data: { user } = { user: null }, loading }] = useLazyQuery<
    FindUniqueUserPayload,
    FindUniqueUserVariables
  >(FIND_UNIQUE_USER)

  const fetchUser = (args: Parameters<typeof getUser>[0]) =>
    new Promise<FindUniqueUserPayload>((resolve, reject) => {
      getUser({
        ...args,
        onCompleted: (data) => {
          args?.onCompleted?.(data)
          resolve(data)
        },
        onError: (error) => {
          args?.onError?.(error)
          reject(error)
        },
      })
    })

  return {
    fetchUser,
    user,
    loading,
  }
}

export default useUserUniqueLazy
