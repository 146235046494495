import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import { SelectionCenterHeader } from 'components/selection-center/header'
import ConfirmationModal from 'components/shared/confirmation-modal'
import HelpMessage from 'components/shared/help-message'
import Loader from 'components/shared/loader'
import { notifyError } from 'context/notifications/trigger'
import { useSelectionCenters } from 'context/selection-centers/provider'
import Button from 'design-system/components/button'
import { ControlledInput } from 'design-system/components/form'
import { Spacer } from 'design-system/components/guide/utils'
import AppLayout from 'design-system/containers/app-layout'
import {
  CREATE_SC_WALK_IN_MEETING,
  CreateSelectionCenterWalkInMeetingPayload,
  CreateSelectionCenterWalkInMeetingVariables,
} from 'queries/meeting'
import { StyledForm } from 'styles/admin/main'
import { emailIsValid, validateName } from 'views/utils'

type WalkInData = {
  firstName: string
  lastName: string
  email: string
}

export const SelectionCenterWalkIn = ({ navigate }: RouteComponentProps) => {
  const { selectionCenter, loading: loadingSelectionCenter } =
    useSelectionCenters()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<WalkInData>({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  })

  const [createWalkIn, { loading }] = useMutation<
    CreateSelectionCenterWalkInMeetingPayload,
    CreateSelectionCenterWalkInMeetingVariables
  >(CREATE_SC_WALK_IN_MEETING)

  const handleCreateWalkIn: SubmitHandler<WalkInData> = async (data) => {
    try {
      if (!selectionCenter) throw new Error('No selection center selected')
      const { data: res } = await createWalkIn({
        variables: {
          data: {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            selectionCenterId: selectionCenter.id,
          },
        },
      })

      if (!res?.createSelectionCenterWalkInMeeting.id)
        throw new Error('Could not create walk-in')
      navigate?.(
        `/selection-centers/visit/${res?.createSelectionCenterWalkInMeeting.id}`,
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (typeof error === 'string') return notifyError(error)
      notifyError(error.message as string)
    }
  }

  if (loadingSelectionCenter) return <Loader />

  return (
    <AppLayout
      fullHeight
      header={
        <SelectionCenterHeader showBackButton backUrl="/selection-centers" />
      }
    >
      <Content>
        <h4>Walk-In Appointment</h4>
        <HelpMessage
          title="Considerations for walk-ins"
          message={`<p className="no-margin">
              <ol>
                <li>
                  Only create a walk-in if the current time slot has already
                  begun
                </li>
                <li>
                  If the next time slot is free, <strong>do not create</strong>
                  the walk-in. Instead, schedule it as a
                  <strong>new appointment</strong> for the next time slot and
                  start early the visit. This will give more time for the client
                  without the risk of overlapping with a sudden appointment
                </li>
                <li>
                  Since walk-ins
                  <strong>
                    should only be created when the next time slot is occupied
                  </strong>
                  , be clear with the clients and let them now that you have
                  less time for the visit. Encourage them to schedule an
                  appointment later on to have the full Selection Center
                  experience
                </li>
                <li>
                  If there is not enough time for a visit, use the opportunity
                  to redirect them to our application via the QR codes. Show
                  them how they can know more about FORM and schedule a visit
                  ahead of time
                </li>
              </ol>
            </p>`}
          icon="warning"
        />
        <Spacer height={42} />
        <StyledForm>
          <Form.Group widths="equal">
            <ControlledInput
              control={control}
              name="firstName"
              placeholder="Marlon"
              label="First Name"
              rules={{
                required: 'This field is required',
                validate: (value) =>
                  validateName(value, {
                    acceptSpecialLetters: true,
                    errorMessage: 'Invalid last name',
                  }),
              }}
            />
            <ControlledInput
              control={control}
              name="lastName"
              placeholder="Morgan"
              label="Last Name"
              rules={{
                required: 'This field is required',
                validate: (value) =>
                  validateName(value, {
                    acceptSpecialLetters: true,
                    errorMessage: 'Invalid last name',
                  }),
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <ControlledInput
              control={control}
              name="email"
              placeholder="hello@formkitchens.com"
              label="Email"
              rules={{
                required: 'This field is required',
                validate: (value) =>
                  emailIsValid(value) || 'Please enter a valid email',
              }}
            />
          </Form.Group>
        </StyledForm>
        <ConfirmationModal
          firstTitle="Are you sure you want to create a walk-in?"
          secondTitle={`This will create a meeting and a prospect for the client. If the client already exists, the meeting will be linked to their project`}
          trigger={
            <Button
              kind="solid"
              text="Create Walk-In"
              disabled={!isValid}
              className="create"
              loading={loading}
            />
          }
          onConfirm={handleSubmit(handleCreateWalkIn)}
          onCancel={() => setOpenConfirmation(false)}
          onOpen={() => setOpenConfirmation(true)}
          open={openConfirmation}
          loading={loading}
        />
      </Content>
    </AppLayout>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 800px;

  & > h4 {
    text-align: center;
    margin-bottom: 32px;
  }

  & > button.create {
    margin: 0 auto;
  }
`
