import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'

import { SelectionCenterHeader } from 'components/selection-center/header'
import Loader from 'components/shared/loader'
import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import { meetingTypeDuration, meetingTypeName } from 'context/meeting/mappers'
import { useMeetingFirstLazy } from 'context/meeting/use-first'
import useMeetingMutateNoContext from 'context/meeting/use-mutate-no-context'
import { notifyError } from 'context/notifications/trigger'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import AppLayout from 'design-system/containers/app-layout'
import { MeetingTypeProject } from 'types/meeting'
import { Prospect } from 'types/prospect'
import { User } from 'types/user'

type SelectionCenterVisitBookCallProps = RouteComponentProps<{
  meeting_id: string
}>

export const SelectionCenterVisitBookCall = ({
  navigate,
  meeting_id,
}: SelectionCenterVisitBookCallProps) => {
  const backUrl = `/selection-centers/visit/${meeting_id}`
  const [checkInId, setCheckInId] = useState('')
  const [disableBackBtn, setDisableBackBtn] = useState(false)
  const [loadingCalendly, setLoadingCalendly] = useState(true)
  const [loadingCheckInCreation, setLoadingCheckInCreation] = useState(true)

  const {
    clientEmail,
    clientName,
    meeting,
    refetchMeeting,
    designer,
    loadingMeeting,
    prospect,
    user,
    project,
  } = useSelectionCenterVisit()

  const { createMeeting, updateMeeting } = useMeetingMutateNoContext()
  const { fetchMeeting } = useMeetingFirstLazy()

  const handleDateTimeSelected = () => {
    setDisableBackBtn(true)
  }
  const handleEventTypeViewed = () => {
    setDisableBackBtn(false)
    setLoadingCalendly(false)
  }
  const handleScheduleMeeting = async () => {
    if (!meeting?.id) return
    const [firstName, lastName] = clientName?.split(' ') ?? []
    await updateMeeting({
      variables: {
        where: {
          id: meeting.id,
        },
        data: {
          metadata: {
            ...meeting.metadata,
            selectionCenter: {
              email: clientEmail,
              firstName,
              lastName,
              ...meeting.metadata.selectionCenter,
              isSCFollowupScheduled: true,
            },
          },
        },
      },
    })
    await refetchMeeting()
    navigate?.(backUrl)
  }

  const renderScheduler = () => {
    if (loadingMeeting) return <Loader flex />

    if (meeting?.project?.id) {
      if (!designer || !checkInId || loadingCheckInCreation) {
        return <Loader flex />
      }

      const [firstName, lastName] = clientName?.split(' ') ?? ['', '']
      return (
        <CheckInScheduler
          designer={designer}
          checkInId={checkInId}
          user={
            user ?? {
              firstName,
              lastName,
              email: clientEmail,
            }
          }
          handleDateTimeSelected={handleDateTimeSelected}
          handleEventTypeViewed={handleEventTypeViewed}
          handleEventScheduled={handleScheduleMeeting}
        />
      )
    }
    if (meeting?.prospect?.id) {
      if (!prospect) {
        return <Loader flex />
      }

      return (
        <DesignCallScheduler
          prospect={prospect}
          handleEventTypeViewed={handleEventTypeViewed}
          handleDateTimeSelected={handleDateTimeSelected}
          handleEventScheduled={handleScheduleMeeting}
        />
      )
    }

    navigate?.(backUrl)
    return null
  }

  useEffect(() => {
    if (project && designer && !checkInId) {
      fetchMeeting({
        variables: {
          where: {
            startTime: { equals: null },
            duration: {
              equals: meetingTypeDuration[MeetingTypeProject.CHECK_IN],
            },
            projectId: {
              equals: project?.id ?? '',
            },
            AND: [
              {
                metadata: {
                  path: ['type'],
                  equals: MeetingTypeProject.CHECK_IN,
                },
              },
            ],
          },
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: ({ meeting: unscheduledCheckIn }) => {
          if (unscheduledCheckIn?.id) {
            setCheckInId(unscheduledCheckIn.id)
            setLoadingCheckInCreation(false)
            return
          }
          createMeeting({
            variables: {
              data: {
                calendlyUrl: designer?.data?.calendlyId
                  ? `https://calendly.com/${designer?.data.calendlyId}/${
                      designer.data.calendlyUrlSlugCheckIn30 ?? 'check-in-30'
                    }`
                  : '',
                metadata: {
                  type: MeetingTypeProject.CHECK_IN,
                  name: meetingTypeName[MeetingTypeProject.CHECK_IN],
                },
                duration: meetingTypeDuration[MeetingTypeProject.CHECK_IN],
                project: {
                  connect: {
                    id: project.id,
                  },
                },
                staffMember: {
                  connect: {
                    id: designer.id,
                  },
                },
              },
            },
            onCompleted: ({ createOneMeeting }) => {
              setCheckInId(createOneMeeting.id)
              setLoadingCheckInCreation(false)
            },
          })
        },
        onError: () => {
          notifyError('Failed to create check-in meeting')
          navigate?.(backUrl)
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designer, project, checkInId])

  //* Redirect effect
  useEffect(() => {
    if (loadingMeeting) return
    if (meeting?.metadata?.selectionCenter?.isSCFollowupScheduled) {
      navigate?.(backUrl)
      return
    }
  }, [
    backUrl,
    loadingMeeting,
    meeting?.metadata?.selectionCenter?.isSCFollowupScheduled,
    navigate,
  ])

  return (
    <AppLayout
      header={
        <SelectionCenterHeader
          showBackButton
          disableBackButton={disableBackBtn}
          backUrl={backUrl}
        />
      }
      fullHeight
    >
      {loadingCalendly && <Loader flex />}
      {renderScheduler()}
    </AppLayout>
  )
}

const CheckInScheduler = ({
  designer,
  checkInId,
  user,
  handleEventTypeViewed,
  handleDateTimeSelected,
  handleEventScheduled,
}: {
  designer: User
  user: Partial<User>
  checkInId: string
  handleEventTypeViewed: () => void
  handleDateTimeSelected: () => void
  handleEventScheduled: () => void
}) => {
  return (
    <CalendlyInlineScheduler
      fullHeight
      calendlyUrl={`https://calendly.com/${designer.data.calendlyId}/${
        designer.data.calendlyUrlSlugCheckIn30 ?? 'check-in-30'
      }`}
      type={MeetingTypeProject.CHECK_IN}
      user={user}
      meetingId={checkInId}
      onEventTypeViewed={handleEventTypeViewed}
      onDateTimeSelected={handleDateTimeSelected}
      onEventScheduled={handleEventScheduled}
    />
  )
}

const DesignCallScheduler = ({
  prospect,
  handleEventTypeViewed,
  handleDateTimeSelected,
  handleEventScheduled,
}: {
  prospect: Prospect
  handleEventTypeViewed: () => void
  handleDateTimeSelected: () => void
  handleEventScheduled: () => void
}) => {
  return (
    <CalendlyInlineScheduler
      fullHeight
      calendlyUrl={process.env.GATSBY_CALENDLY_DESIGN_CALL_URL || ''}
      prospectEmail={prospect.email}
      type={MeetingTypeProject.DESIGN}
      user={{
        firstName: prospect.firstName,
        lastName: prospect.lastName,
        email: prospect.email,
      }}
      onEventTypeViewed={handleEventTypeViewed}
      onDateTimeSelected={handleDateTimeSelected}
      onEventScheduled={handleEventScheduled}
    />
  )
}
