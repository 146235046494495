import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { useSelectionCenters } from 'context/selection-centers/provider'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import { getNameForUser } from 'views/utils'

import { SelectionCenterHeader } from '../header'

const formatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
  timeZoneName: 'short',
}

type SelectionCenterVisitLayoutProps = {
  children: React.ReactNode
  mainRef?: React.RefObject<HTMLDivElement>
  backUrl?: string
}

export const SelectionCenterVisitLayout = ({
  children,
  mainRef,
  backUrl = '/selection-centers/admin',
}: SelectionCenterVisitLayoutProps) => {
  const { selectionCenter } = useSelectionCenters()
  const { meeting, setOpenNotes } = useSelectionCenterVisit()
  const [formatter, setFormatter] = useState<Intl.DateTimeFormat>(
    new Intl.DateTimeFormat('en-US', formatOptions),
  )

  useEffect(() => {
    if (!selectionCenter) return
    setFormatter(
      new Intl.DateTimeFormat('en-US', {
        ...formatOptions,
        timeZone: selectionCenter?.data?.timezone,
      }),
    )
  }, [selectionCenter])

  const headerItems = [
    {
      label: 'Selection Center',
      value: selectionCenter?.name,
    },
    {
      label: 'Manager',
      value: getNameForUser(meeting?.staffMember),
    },
    {
      label: 'Date',
      value: formatter.format(
        meeting?.startTime ? new Date(meeting.startTime) : new Date(),
      ),
    },
  ]

  return (
    <Container>
      <SelectionCenterHeader
        showBackButton
        backUrl={backUrl}
        actions={
          <Button
            kind="ghost"
            fontAwesomeIcon="note-sticky"
            onClick={() => setOpenNotes(true)}
          />
        }
        sticky
      />
      <HeaderDiv>
        {headerItems.map((item, index) => (
          <div key={`${item.label}#${index}`}>
            <p className="x-small no-margin">{item.label}</p>
            <p className="small no-margin bold">{item.value}</p>
          </div>
        ))}
      </HeaderDiv>
      <StyledMain ref={mainRef}>{children}</StyledMain>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${Colors.white};
  min-height: 100vh;
`

const HeaderDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  background-color: ${Colors.gray50};
  padding: 24px 48px;
  justify-content: space-evenly;
`

const StyledMain = styled.main`
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 48px;

  div.project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
