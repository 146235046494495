import React from 'react'

import { Router } from '@reach/router'
import 'moment-timezone'

import ErrorBoundary from 'components/shared/error-boundary'
import SemanticUIFull from 'components/shared/semantic-ui-full'
import SEO from 'components/shared/seo'
import Tracking from 'components/shared/tracking'
import useAuth from 'context/auth/use'
import SelectionCentersProvider from 'context/selection-centers/provider'
import { StyledContent } from 'styles/app/system'
import CatalogIndex from 'views/app/catalog'
import CatalogNobiliaOption from 'views/app/catalog/nobilia-option'
import SelectionCenterIndex from 'views/selection-centers'
import SelectionCenterAdminScheduleBooker from 'views/selection-centers/schedule/booker'
import { SelectionCenterWalkIn } from 'views/selection-centers/schedule/walk-in'
import SelectionCenterUpcomingMeetings from 'views/selection-centers/upcoming-meetings'
import SelectionCenterVisitIndex from 'views/selection-centers/visit'
import { SelectionCenterVisitBookCall } from 'views/selection-centers/visit/book-call'
import SelectionCenterVisitProjectBrief from 'views/selection-centers/visit/brief'
import SelectionCenterFrontsSelection from 'views/selection-centers/visit/fronts-selection'
import { SelectionCenterVisitMain } from 'views/selection-centers/visit/main'
import { SelectionCenterVisitSummaryNotes } from 'views/selection-centers/visit/summary-notes'

const SelectionCenter = () => {
  const { isAuthenticated, isFORMOrExternalStaff } = useAuth()
  const isEnvTracked =
    process.env.GATSBY_ACTIVE_ENV === 'production' ||
    process.env.GATSBY_ACTIVE_ENV === 'stage'

  return (
    <StyledContent>
      <SemanticUIFull />
      <Tracking
        segment={!!isEnvTracked}
        fullStory={
          process.env.GATSBY_ACTIVE_ENV === 'production' &&
          isAuthenticated &&
          !isFORMOrExternalStaff
        }
      />

      <ErrorBoundary>
        <SelectionCentersProvider
          skipLoader
          defaultFilters={{
            take: 20,
          }}
        >
          <Router>
            <SelectionCenterIndex path="/selection-centers">
              <SelectionCenterUpcomingMeetings default />
              <SelectionCenterAdminScheduleBooker path="schedule" />
              <SelectionCenterWalkIn path="walk-in" />
              <SelectionCenterVisitIndex path="visit/:meeting_id">
                <SelectionCenterVisitMain default />
                <SelectionCenterVisitBookCall path="book-call" />
                <SelectionCenterVisitSummaryNotes path="summary-notes" />
                <SelectionCenterVisitProjectBrief path="brief" />
                <SelectionCenterFrontsSelection path="fronts/selection" />
                <CatalogIndex path="catalog/:catalog_id">
                  <CatalogNobiliaOption path="nobilia-features/:feature_no/nobilia-options/:option_id" />
                </CatalogIndex>
              </SelectionCenterVisitIndex>
            </SelectionCenterIndex>
          </Router>
        </SelectionCentersProvider>
      </ErrorBoundary>
    </StyledContent>
  )
}

export default SelectionCenter

export const Head = () => (
  // TODO - get correct copy
  <SEO title="FORM Studios" description="FORM Studios" />
)
