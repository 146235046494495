import React, { useEffect, useRef, useState } from 'react'

import { Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { useMeetingUserOrProspect } from 'context/selection-centers/use-meeting-user-or-prospect'
import { Colors } from 'styles/app/system'
import { Meeting } from 'types/meeting'
import { ProjectType } from 'types/project'

import {
  SelectionCenterMeetingTag,
  SelectionCenterMeetingTagStatus,
  SelectionCenterMeetingTagType,
} from './meeting-tag'

type SelectionCenterMeetingCardProps = {
  meeting: Meeting
  onClick?: () => void
  isTomorrowMeeting?: boolean
  timeZone?: string
}

export const SelectionCenterMeetingCard = ({
  meeting,
  onClick,
  isTomorrowMeeting,
  timeZone,
}: SelectionCenterMeetingCardProps) => {
  const { user, prospect, clientName } = useMeetingUserOrProspect(meeting)
  const [status, setStatus] = useState<SelectionCenterMeetingTagStatus>()
  const interval = useRef<NodeJS.Timeout>()

  let projectType = SelectionCenterMeetingTagType.PROSPECT
  if (meeting.project || user) {
    projectType =
      meeting.project?.data.projectType === ProjectType.TRADE
        ? SelectionCenterMeetingTagType.TRADE
        : SelectionCenterMeetingTagType.HOMEOWNER
  }

  const startTimeLabel = meeting.startTime
    ? new Date(meeting.startTime).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone,
      })
    : ''

  const checkMeetingStatus = () => {
    let shouldClearInterval = false
    if (meeting.canceledAt) {
      setStatus(SelectionCenterMeetingTagStatus.CANCELED)
      shouldClearInterval = true
    } else if (meeting.metadata.customerNoShow) {
      //* One potential data mismatch is that we are not fetching the meeting again,
      //* so it will be marked as done even if it is a no show. Once the page reloads,
      //* the meeting will be marked as no show. Since this is a rare case, we are not
      //* fetching the meeting again to avoid unnecessary API calls.
      setStatus(SelectionCenterMeetingTagStatus.NO_SHOW)
      shouldClearInterval = true
    } else if (meeting.startTime) {
      const meetingDate = new Date(meeting.startTime)
      const duration = meeting.duration || 30
      const meetingEndedBy = new Date(meetingDate.getTime())
      meetingEndedBy.setMinutes(meetingEndedBy.getMinutes() + duration)

      if (Date.now() < meetingDate.getTime()) {
        setStatus(SelectionCenterMeetingTagStatus.PENDING)
      } else if (Date.now() > meetingEndedBy.getTime()) {
        setStatus(SelectionCenterMeetingTagStatus.DONE)
        shouldClearInterval = true
      } else {
        setStatus(SelectionCenterMeetingTagStatus.IN_PROGRESS)
      }
    }

    if (shouldClearInterval && interval.current) {
      clearInterval(interval.current)
    }
  }

  useEffect(() => {
    if (!isTomorrowMeeting) {
      interval.current = setInterval(() => {
        checkMeetingStatus()
      }, 60000) //* 1 minute
    }
    checkMeetingStatus()

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [])

  return (
    <CardDiv
      meetingStatus={status}
      clickAvailable={!!onClick}
      onClick={onClick}
    >
      <div className="client-info">
        {user ? (
          <Image avatar src={user.picture} />
        ) : (
          <div className="avatar">
            {prospect
              ? `${prospect.firstName.charAt(0)}${prospect.lastName.charAt(0)}`
              : '--'}
          </div>
        )}
        <p className="small no-margin">{clientName}</p>
        <SelectionCenterMeetingTag type={projectType} />
      </div>
      <div>
        <SelectionCenterMeetingTag status={status} />
      </div>
      <p className="small no-margin">{startTimeLabel}</p>
    </CardDiv>
  )
}

const CardDiv = styled.div<{
  meetingStatus?: SelectionCenterMeetingTagStatus
  clickAvailable: boolean
}>`
  align-items: center;
  background: ${(props) =>
    props.meetingStatus === SelectionCenterMeetingTagStatus.IN_PROGRESS
      ? Colors.red50
      : Colors.white};
  border: ${(props) =>
    props.meetingStatus === SelectionCenterMeetingTagStatus.IN_PROGRESS
      ? `1px solid #F4DCD4`
      : `none`};
  border-radius: 6px;
  box-shadow: 0px 3px 15px 0px rgba(120, 113, 108, 0.07);
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  justify-content: space-between;
  padding: 8px;

  ${(props) =>
    props.clickAvailable &&
    css`
      cursor: pointer;
      transition: box-shadow 0.2s ease-in-out;

      &:hover {
        box-shadow: 0px 3px 15px 0px rgba(120, 113, 108, 0.2);
      }
    `}

  div.client-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  div.avatar {
    background-color: ${Colors.red400};
    border-radius: 50%;
    color: ${Colors.gray900};
    font-family: 'GT America';
    font-size: 10px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
