import React, { useCallback, useMemo } from 'react'

import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import styled from 'styled-components'

import { UserInfo } from 'components/shared/user-info'
import { getAddressString } from 'context/address/utils'
import { parseOwners, parseStaff } from 'context/project/utils'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { ButtonTooltip } from 'design-system/components/button'
import {
  FIND_MANY_PROJECT,
  FindManyProjectPayload,
  FindManyProjectVariables,
} from 'queries/project'
import { Colors } from 'styles/app/system'
import { Project } from 'types/project'
import { capsSnakeCaseToTitleCase, getNameForUser } from 'views/utils'

export const SelectionCenterLinkProjectBar = () => {
  const {
    project,
    loadingMeeting,
    loadingUpdateMeeting,
    handleAttachProject,
    handleRemoveProject,
  } = useSelectionCenterVisit()
  const [fetchProjects, { loading, data }] = useLazyQuery<
    FindManyProjectPayload,
    FindManyProjectVariables
  >(FIND_MANY_PROJECT)

  const handleSearchChange = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { searchQuery }: DropdownProps,
  ) => {
    const sanitizedQuery = searchQuery?.trim().replace(/[^a-zA-Z0-9_\s@.]/g, '')
    if (!sanitizedQuery) return

    fetchProjects({
      variables: {
        where: {
          derivedSearch: {
            contains: sanitizedQuery,
            mode: 'insensitive',
          },
        },
      },
    })
  }

  const handleSearchChangeDebounced = useCallback(
    debounce(handleSearchChange, 1000),
    [],
  )

  return project ? (
    <LinkedProjectCard
      project={project}
      handleRemoveProject={handleRemoveProject}
      loading={loadingUpdateMeeting || loadingMeeting}
    />
  ) : (
    <DDContainer className="field required">
      <Dropdown
        search
        fluid
        required
        placeholder="Link a Project"
        onSearchChange={handleSearchChangeDebounced}
        loading={loading || loadingUpdateMeeting || loadingMeeting}
        className="selection"
      >
        <Dropdown.Menu>
          {data?.count ? (
            data.projects?.map((project) => {
              const { primaryOwner } = parseOwners(project)

              return (
                <Dropdown.Item
                  key={project.id}
                  onClick={() => handleAttachProject(project)}
                >
                  <UserInfo
                    name={getNameForUser(primaryOwner)}
                    picture={primaryOwner.picture}
                    content={getAddressString(project.projectAddress)}
                  />
                </Dropdown.Item>
              )
            })
          ) : (
            <Dropdown.Item>
              <p>No projects found</p>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </DDContainer>
  )
}

const LinkedProjectCard = ({
  loading,
  project,
  handleRemoveProject,
}: {
  loading?: boolean
  project: Project
  handleRemoveProject: () => void
}) => {
  const parsedOwners = useMemo(() => parseOwners(project), [project?.owners])

  const parsedStaff = useMemo(() => parseStaff(project), [project?.staff])

  return (
    <ProjectCard>
      <ButtonTooltip
        kind="ghost"
        fontAwesomeIcon="times"
        onClick={handleRemoveProject}
        tooltipProps={{
          label: 'Detach Project',
        }}
        className="detach"
        loading={loading}
      />
      <div className="project-info-div">
        <p className="no-margin x-small">Project Owners</p>
        <div>
          {parsedOwners.allOwners.map((owner) => (
            <UserInfo
              key={owner.id}
              name={getNameForUser(owner)}
              picture={owner.picture}
              phone={owner.phone}
            />
          ))}
        </div>
      </div>
      <div className="project-info-div">
        <p className="no-margin x-small">Primary Desiger</p>
        <UserInfo
          name={getNameForUser(parsedStaff.primaryDesigner)}
          picture={parsedStaff.primaryDesigner?.picture}
          content={capsSnakeCaseToTitleCase(parsedStaff.primaryDesigner?.type)}
        />
      </div>
      <div className="project-info-div">
        <p className="no-margin x-small">Address</p>
        <p className="no-margin">{getAddressString(project.projectAddress)}</p>
      </div>
    </ProjectCard>
  )
}

const DDContainer = styled.div`
  width: 100%;
  max-width: 360px;
`

const ProjectCard = styled.div`
  background-color: ${Colors.gray50};
  box-shadow: 0px 3px 15px 0px rgba(120, 113, 108, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 500px;
  padding: 16px;
  position: relative;
  row-gap: 24px;

  .project-info-div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    & > div {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
  }

  .col-2 {
    display: grid;
    grid-template-columns: auto auto;
  }

  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(120, 113, 108, 0.1);
  }

  button.detach {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`
