import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { NobiliaOption } from 'types/nobilia-option'

import FrontsBrowser from './fronts-browser'

type FrontsSelectionProps = RouteComponentProps

const FrontsSelection = (_: FrontsSelectionProps) => {
  const { addFinish, removeFinish, finishes, catalog, meeting } =
    useSelectionCenterVisit()

  const onBack = () => {
    navigate(`/selection-centers/visit/${meeting?.id}`)
  }

  const onSave = (item: Partial<NobiliaOption>) => {
    if (finishes?.find((s) => s.id === item.id)) {
      removeFinish(item as NobiliaOption)
    } else {
      addFinish(item as NobiliaOption)
    }
  }

  return (
    <FrontsBrowser
      catalog={catalog}
      frontsSelected={finishes}
      onBack={onBack}
      onSave={onSave}
    />
  )
}

export default FrontsSelection
