import React from 'react'

import { navigate } from '@reach/router'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { Front } from 'context/selection-system/fronts'
import Button from 'design-system/components/button'
import SelectionCover from 'design-system/components/guide/selection-cover'

import { NobiliaOptionColumn } from './fronts-browser'

export const SelectionCenterFinishesSection = () => {
  const { meeting, finishes, loadingNobiliaOptions } = useSelectionCenterVisit()

  return !finishes.length ? (
    <Container id="finishes-wall">
      <SelectionCover
        loading={loadingNobiliaOptions}
        image="design-call/kickoff-fronts.png"
        onClick={() =>
          navigate(`/selection-centers/visit/${meeting?.id}/fronts/selection`)
        }
        title="Select fronts"
      />
    </Container>
  ) : (
    <Grid doubling columns={4}>
      <Grid.Row stretched className="row-gap">
        {finishes.map((f) => (
          <NobiliaOptionColumn
            key={f.id}
            category={''}
            front={f as Front}
            frontsSelected={finishes}
          />
        ))}
      </Grid.Row>
      <Grid.Row centered>
        <Button
          text="Change Selection"
          kind="solid"
          color="blue"
          fontAwesomeIcon="arrow-right"
          onClick={() =>
            navigate(`/selection-centers/visit/${meeting?.id}/fronts/selection`)
          }
        />
      </Grid.Row>
    </Grid>
  )
}

const Container = styled.div`
  width: 100%;
`
