import React, { useEffect, useMemo, useRef } from 'react'

import styled from 'styled-components'

import { Attachments } from 'components/shared/message'
import { AttachmentsButton } from 'components/shared/rich-text-editor/utils/action-buttons'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import useFORMEmployees from 'context/user/use-form-employees'
import Button from 'design-system/components/button'
import { Spacer } from 'design-system/components/guide/utils'
import { ModalBasic } from 'design-system/components/modal'
import RichText from 'design-system/components/rich-text'
import { ProjectFile } from 'types/project-file'
import {
  SC_SESSION_STORAGE_KEY,
  SelectionCenterSessionStorage,
} from 'types/selection-center'
import { getSessionStorage, setSessionStorage } from 'utils/session-storage'
import { getNameForUser } from 'views/utils'

export const NotesDrawer = () => {
  const {
    richTextApi,
    setRichTextApi,
    noteAttachments,
    setNotesAttachments,
    openNotes,
    setOpenNotes,
    isSummaryNotesSubmitted,
    summaryNote,
    meeting,
  } = useSelectionCenterVisit()
  const { users: staff } = useFORMEmployees()
  const mentions = useMemo(
    () => staff.map((s) => ({ id: s.id, label: getNameForUser(s) })),
    [staff],
  )

  const handleRemoveFile = (file: File | ProjectFile) => {
    setNotesAttachments(noteAttachments.filter((f) => f.name !== file.name))
  }

  const updateSessionStorage = () => {
    if (!meeting?.id || richTextApi?.isEmpty || !richTextApi) return
    const currentData = getSessionStorage<SelectionCenterSessionStorage>(
      SC_SESSION_STORAGE_KEY,
    )

    const newData: SelectionCenterSessionStorage = {
      ...currentData,
      [meeting.id]: {
        ...currentData?.[meeting.id],
        note: richTextApi.getJSON(),
      },
    }
    setSessionStorage(SC_SESSION_STORAGE_KEY, newData)
  }

  const getSessionStorageState = () => {
    if (summaryNote?.body) {
      richTextApi?.commands.setContent(summaryNote.body)
    } else if (meeting?.id) {
      const currentSessionData =
        getSessionStorage<SelectionCenterSessionStorage>(SC_SESSION_STORAGE_KEY)
      const note = currentSessionData?.[meeting.id]?.note
      if (note) {
        richTextApi?.commands.setContent(note)
      }
    }
  }

  const handleModalClose = () => {
    updateSessionStorage()
    setOpenNotes(false)
  }

  useEffect(() => {
    richTextApi?.setEditable(!isSummaryNotesSubmitted)
  }, [isSummaryNotesSubmitted, richTextApi])

  return (
    <Modal
      open={openNotes}
      onClose={handleModalClose}
      unmountOnExit={false}
      onEntered={() => {
        getSessionStorageState()
        if (!isSummaryNotesSubmitted) richTextApi?.commands.focus()
      }}
      disableEscape
      containerClasses="content"
      disableBackdropClick
      header={
        <div className="modal-header">
          <h4 className="no-margin">Visit Notes</h4>

          <Button
            kind="ghost"
            fontAwesomeIcon="times"
            color="gray"
            onClick={handleModalClose}
            iconAnimation="scale"
            iconSize="lg"
          />
        </div>
      }
    >
      <Spacer height={24} />
      <div tabIndex={-1} id="adminRoot" />
      <RichText
        // Temp workaround for rich text suggestions plugins (mentions, hashtags)
        key={mentions.length}
        placeholder="Start adding notes for the visit"
        maxHeight="calc(100% - 140px)"
        fullHeight
        getRichTextApi={setRichTextApi}
        mentions={mentions}
        editable={!isSummaryNotesSubmitted}
        helperRow={
          <Attachments
            readOnly={isSummaryNotesSubmitted}
            files={noteAttachments}
            projectFiles={summaryNote?.attachments}
            handleRemove={handleRemoveFile}
          />
        }
        actions={
          !isSummaryNotesSubmitted ? (
            <AddAttachments
              attachments={noteAttachments}
              setAttachments={setNotesAttachments}
              loading={false}
            />
          ) : null
        }
      />
    </Modal>
  )
}

const Modal = styled(ModalBasic)`
  .content {
    height: 100%;
    width: 100%;
    padding-inline: 1.5rem;
    padding-block: 2.5rem;
    padding-top: 0;
  }
`

type AddAttachmentsProps = {
  attachments: File[]
  loading: boolean
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>
}

const AddAttachments = ({
  attachments,
  loading,
  setAttachments,
}: AddAttachmentsProps) => {
  const filesRef = useRef<HTMLInputElement>(null)
  const onChangeFileInput = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setAttachments(attachments.concat(Array.prototype.slice.call(files)))
  }

  const handleAddFiles = () => {
    filesRef.current?.click()
  }

  return (
    <>
      <AttachmentsButton
        loading={loading}
        onClick={handleAddFiles}
        disabled={loading}
      />

      <input
        id="addAttachments"
        hidden
        type="file"
        multiple
        ref={filesRef}
        onChange={onChangeFileInput}
      />
    </>
  )
}
