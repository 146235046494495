import React from 'react'

import { Redirect, RouteComponentProps } from '@reach/router'
import styled from 'styled-components'

import {
  SelectionCenterLinkProjectBar,
  SelectionCenterVisitLayout,
} from 'components/selection-center/visit'
import Loader from 'components/shared/loader'
import { useSelectionCenters } from 'context/selection-centers/provider'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { Colors } from 'styles/app/system'

import { SelectionCenterFinishesSection } from './finishes'
import { SelectionCenterNextSteps } from './next-steps'
import { SelectionCenterUsefulLinks } from './useful-links'

type SelectionCenterVisitMainProps = RouteComponentProps

export const SelectionCenterVisitMain = (_: SelectionCenterVisitMainProps) => {
  const { selectionCenter } = useSelectionCenters()
  const { meeting, loadingMeeting, clientName } = useSelectionCenterVisit()
  const mainRef = React.useRef<HTMLDivElement>(null)

  if (!selectionCenter || loadingMeeting) return <Loader />

  if (!meeting) return <Redirect to="/selection-centers/admin" noThrow />

  const calendlyNote =
    meeting.calendlyResponse?.payload.questions_and_answers[1]?.answer

  return (
    <SelectionCenterVisitLayout mainRef={mainRef}>
      <div className="project-info">
        <h2>{clientName}</h2>
        {!!calendlyNote && <CalendlyNote>{calendlyNote}</CalendlyNote>}
        <p className="subtitle">Selection Center Visit</p>
        <SelectionCenterLinkProjectBar />
      </div>
      <SelectionCenterFinishesSection />
      <SelectionCenterUsefulLinks />
      <SelectionCenterNextSteps />
    </SelectionCenterVisitLayout>
  )
}

const CalendlyNote = styled.p.attrs({ className: 'small' })`
  background-color: ${Colors.blue50};
  padding: 8px;
  width: 350px;
`
