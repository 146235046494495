import { JSONContent } from '@tiptap/react'

import { SelectionCenterStaff } from 'types/selection-center-staff'

export type SelectionCenter = {
  id: string
  data?: SelectionCenterData
  identifier: string
  name: string
  createdAt?: Date | string
  updatedAt?: Date | string
  staff: SelectionCenterStaff[]
}

export type SelectionCenterData = {
  address?: string
  calendlyUrl?: string
  illustration?: string
  timezone?: string
  locationUrl?: string
  picture?: string
  videoUrl?: string
}

export const SC_SESSION_STORAGE_KEY = 'selectionCenter'

export type SelectionCenterSessionStorage = Record<
  string,
  {
    fronts?: string[]
    note?: JSONContent
  }
>
