import React from 'react'

import styled from 'styled-components'

import { Colors } from 'styles/app/system'

export enum SelectionCenterMeetingTagType {
  PROSPECT = 'prospect',
  TRADE = 'trade',
  HOMEOWNER = 'homeowner',
}

export enum SelectionCenterMeetingTagStatus {
  CANCELED = 'canceled',
  DONE = 'done',
  IN_PROGRESS = 'inProgress',
  NO_SHOW = 'noShow',
  PENDING = 'pending',
}

type SelectionCenterMeetingTagProps = {
  type?: SelectionCenterMeetingTagType
  status?: SelectionCenterMeetingTagStatus
}

export const SelectionCenterMeetingTag = (
  props: SelectionCenterMeetingTagProps,
) => {
  if (props.type) {
    return (
      <Tag bgColor={Colors.blue100} color={Colors.blue900}>
        {mapTypeToLabel[props.type]}
      </Tag>
    )
  }
  switch (props.status) {
    case SelectionCenterMeetingTagStatus.PENDING:
      return (
        <Tag bgColor="#FFF0D9" color="#6C4000">
          Pending
        </Tag>
      )
    case SelectionCenterMeetingTagStatus.DONE:
      return (
        <Tag bgColor="#D7F3DC" color="#3D5903">
          Done
        </Tag>
      )
    case SelectionCenterMeetingTagStatus.NO_SHOW:
      return (
        <Tag bgColor="#FFDAE7" color="#830000">
          No Show
        </Tag>
      )
    case SelectionCenterMeetingTagStatus.IN_PROGRESS:
      return (
        <Tag bgColor="#E2ECFC" color="#03407E">
          In Progress
        </Tag>
      )
    case SelectionCenterMeetingTagStatus.CANCELED:
      return (
        <Tag bgColor="#FAC7D1" color="#03407E">
          Canceled
        </Tag>
      )
    default:
      return null
  }
}

const Tag = styled.p.attrs({
  className: 'no-margin x-small gray',
})<{ color: string; bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.color};
  padding: 0px 8px;
  width: max-content;
`

const mapTypeToLabel = {
  prospect: 'Prospect',
  trade: 'Trade',
  homeowner: 'Homeowner',
}
