import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Form, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { SelectionCenterHeader } from 'components/selection-center/header'
import { SelectionCenterMeetingMultipleCards } from 'components/selection-center/meeting-multiple-cards'
import useAuth from 'context/auth/use'
import { useSelectionCenterManyMeetings } from 'context/meeting/use-selection-center'
import { useSelectionCenters } from 'context/selection-centers/provider'
import Button from 'design-system/components/button'
import { Drawer } from 'design-system/components/drawer'
import AppLayout from 'design-system/containers/app-layout'

const SelectionCenterUpcomingMeetings = (_: RouteComponentProps) => {
  const { selectionCenter, selectionCenters, setSelectionCenter } =
    useSelectionCenters()
  const { isSuperAdmin } = useAuth()
  const {
    meetingsToday,
    loadingMeetingsToday,
    loadingTomorrowMeetings,
    meetingsTomorrow,
    fetchTomorrowMeetings,
  } = useSelectionCenterManyMeetings(selectionCenter?.identifier)
  const [openMeetingsToday, setOpenMeetingsToday] = useState(false)

  useEffect(() => {
    if (!loadingMeetingsToday) setOpenMeetingsToday(true)
  }, [loadingMeetingsToday])

  return (
    <AppLayout header={<SelectionCenterHeader />} fullHeight>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <HeaderRow>
              <div>
                <p className="x-small no-margin light-gray">
                  Booking a visit at
                </p>
                <h3 className="no-margin">{selectionCenter?.name}</h3>
                <p className="subtitle no-margin">Selection Center</p>
                {isSuperAdmin ? (
                  <Form.Dropdown
                    placeholder="Selection Centers"
                    options={selectionCenters.map((sc) => ({
                      value: sc.identifier,
                      text: sc.name,
                    }))}
                    onChange={(_, { value }) =>
                      setSelectionCenter((value as string) ?? null)
                    }
                    value={selectionCenter?.identifier}
                  />
                ) : null}
              </div>

              <ActionsRow>
                <Button
                  kind="outlined"
                  text="Walk-In"
                  fontAwesomeIcon="timer"
                  color="gray"
                  onClick={() => navigate('/selection-centers/walk-in')}
                />
                <Button
                  kind="outlined"
                  text="Go to dashboard"
                  fontAwesomeIcon="columns"
                  onClick={() => navigate('/admin/dashboard')}
                />
                <Button
                  kind="solid"
                  text="New appointment"
                  fontAwesomeIcon="calendar-alt"
                  iconPosition="right"
                  onClick={() => navigate('/selection-centers/schedule')}
                />
              </ActionsRow>
            </HeaderRow>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Drawer
              title="Today"
              allowOutsideClick
              loading={loadingMeetingsToday}
              open={openMeetingsToday}
              setOpen={setOpenMeetingsToday}
              content={
                <SelectionCenterMeetingMultipleCards
                  meetings={meetingsToday}
                  timeZone={selectionCenter?.data?.timezone}
                />
              }
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Drawer
              title="Tomorrow"
              allowOutsideClick
              onChange={(opened) => {
                if (opened) {
                  fetchTomorrowMeetings()
                }
              }}
              loading={loadingTomorrowMeetings}
              content={
                <SelectionCenterMeetingMultipleCards
                  meetings={meetingsTomorrow}
                  isTomorrowMeeting
                />
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`

export default SelectionCenterUpcomingMeetings
