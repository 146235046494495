import React from 'react'

import { RouteComponentProps } from '@reach/router'

import { NotesDrawer } from 'components/selection-center/notes-drawer'
import SelectionCenterVisitProvider from 'context/selection-centers/visit/provider'

const SelectionCenterVisitIndex = ({
  meeting_id,
  children,
}: RouteComponentProps<{ meeting_id: string }> & {
  children: React.ReactNode
}) => {
  return (
    <SelectionCenterVisitProvider meetingId={meeting_id ?? ''}>
      {children}
      <NotesDrawer />
    </SelectionCenterVisitProvider>
  )
}

export default SelectionCenterVisitIndex
