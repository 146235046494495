import { useQuery } from '@apollo/client'

import {
  FIND_UNIQUE_MEETING,
  FIND_UNIQUE_MEETING_SHALLOW,
  FindOneMeetingPayload,
  FindOneMeetingVariables,
} from 'queries/meeting'

export const useUniqueMeeting = (
  meetingId: string,
  opts?: { shallow?: boolean },
) => {
  const { data, ...rest } = useQuery<
    FindOneMeetingPayload,
    FindOneMeetingVariables
  >(opts?.shallow ? FIND_UNIQUE_MEETING_SHALLOW : FIND_UNIQUE_MEETING, {
    variables: {
      where: {
        id: meetingId,
      },
    },
  })

  return {
    meeting: data?.meeting,
    ...rest,
  }
}
