import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Editor, JSONContent } from '@tiptap/react'
import styled from 'styled-components'

import { SelectionCenterVisitLayout } from 'components/selection-center/visit'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { Attachments } from 'components/shared/message'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import Button from 'design-system/components/button'
import RichText from 'design-system/components/rich-text'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { NobiliaOption } from 'types/nobilia-option'
import { User } from 'types/user'
import { getNameForUser } from 'views/utils'

type SelectionCenterVisitSummaryNotesProps = RouteComponentProps

export const SelectionCenterVisitSummaryNotes = ({
  navigate,
}: SelectionCenterVisitSummaryNotesProps) => {
  const {
    richTextApi,
    handleSubmitNote,
    loadingSubmitNote,
    designer,
    finishes,
    meeting,
    noteAttachments,
    isSummaryNotesSubmitted,
  } = useSelectionCenterVisit()
  const [openModal, setOpenModal] = useState(false)
  const [noteApi, setNoteApi] = useState<Editor | null>(null)

  const { isValidNote, noteJsonData } = generateNoteData(
    richTextApi,
    finishes,
    designer,
  )

  const handleConfirm = async () => {
    if (!noteJsonData || !noteApi) return

    const success = await handleSubmitNote({
      html: noteApi.getHTML(),
      json: noteApi.getJSON(),
      text: noteApi.getText(),
    })
    setOpenModal(false)

    if (success) {
      navigate?.(`/selection-centers/visit/${meeting?.id}`)
    }
  }

  return (
    <SelectionCenterVisitLayout
      backUrl={`/selection-centers/visit/${meeting?.id}`}
    >
      <div className="project-info">
        <h2>Summary Notes</h2>
      </div>

      <SummmaryDiv>
        <RichText content={noteJsonData} getRichTextApi={setNoteApi} mentions />
        {noteAttachments.length ? (
          <Attachments readOnly files={noteAttachments} />
        ) : null}
      </SummmaryDiv>

      <ConfirmationModal
        firstTitle="Are you sure you want to submit the visit note?"
        secondTitle="The sales designer will be notified and you won’t be able to edit the note from here"
        trigger={
          <Button
            kind="solid"
            text="Submit"
            size="large"
            disabled={!isValidNote || isSummaryNotesSubmitted}
          />
        }
        onConfirm={handleConfirm}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onOpen={() => setOpenModal(true)}
        loading={loadingSubmitNote}
      />
    </SelectionCenterVisitLayout>
  )
}

const SummmaryDiv = styled.div`
  ${ScrollBarStyle}

  border: 1px solid ${Colors.gray200};
  border-radius: 4px;
  height: 60vh;
  overflow: auto;
  padding: 16px 24px;
  width: 80%;
`

const createFinishesJsonContent = (
  finishes: NobiliaOption[],
): JSONContent[] => {
  return [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          marks: [{ type: 'bold' }],
          text: 'Finishes Selected',
        },
      ],
    },
    {
      type: 'orderedList',
      attrs: { start: 1 },
      content: finishes.map((no) => {
        return {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: no.description,
                  marks: [
                    {
                      type: 'link',
                      attrs: {
                        href: `/catalog/fronts/${no.optionKey}`,
                        target: '_blank',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        }
      }),
    },
    { type: 'paragraph' },
  ]
}

const generateNoteData = (
  richTextApi: Editor | null,
  finishes: NobiliaOption[],
  designer?: User | null,
) => {
  if (!richTextApi) return { isValidNote: false, noteData: null }
  const json = richTextApi.getJSON().content ?? []
  const isValidNote = !richTextApi.isEmpty

  const automaticJson: JSONContent[] = [
    ...createFinishesJsonContent(finishes),
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Manager Notes',
          marks: [
            {
              type: 'bold',
            },
          ],
        },
      ],
    },
  ]

  const newContent = [...automaticJson, ...json].filter(
    (item) => !!item.content?.length,
  )

  if (designer) {
    const name = getNameForUser(designer)

    newContent.push({
      type: 'paragraph',
      content: [
        {
          attrs: {
            id: designer.id,
            label: name,
            url: null,
          },
          type: 'mentions-plugin',
        },
      ],
    })
  }

  const noteJsonData = {
    type: 'doc',
    content: newContent,
  }

  return { isValidNote, noteJsonData }
}
