import React, { useEffect } from 'react'

import styled from 'styled-components'

import EstimatePlanner from 'components/shared/estimate/estimate-planner'
import {
  SummaryContainer,
  SummaryItem,
} from 'components/shared/estimate/estimate-summary/styles'
import PriceLabel from 'components/shared/estimate/price-label'
import Loader from 'components/shared/loader'
import {
  CabinetHeightIcon,
  DimensionsIcon,
  IntegratedLightningIcon,
  LayoutIcon,
  NoIntegratedLightningIcon,
  StyleIcon,
  UpperStorageIcon,
} from 'components/welcome/estimate/icons'
import useEstimatePlanner from 'context/estimate/use-estimate-planner'
import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { NodeRelationshipGraph } from 'planner/compose'
import { formatDimensionFeetAndInches } from 'planner/utils/measurement-utils'
import { Prospect } from 'types/prospect'
import { getPriceFromUpgrades } from 'views/app/project/room/design-call-guide/sections/budget'

const EstimateData = () => {
  const { prospect } = useSelectionCenterVisit()
  const {
    sceneRelationshipGraph,
    sceneState,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
    loadingUpgrades,
    upgrades,
    loading,
    updateUpgrades,
  } = useEstimatePlanner(prospect)
  const estimate = prospect?.data.estimate

  const prices = estimate ? getPriceFromUpgrades(upgrades) : null

  useEffect(() => {
    if (!loading) updateUpgrades()
  }, [loading])

  return !loading ? (
    <>
      <PlannerContainer>
        <EstimatePlanner
          sceneRelationshipGraph={
            sceneRelationshipGraph as NodeRelationshipGraph
          }
          sceneState={sceneState}
          viewBoxBase={viewBoxBase}
          viewBoxPanX={viewBoxPanX}
          viewBoxPanY={viewBoxPanY}
        />
      </PlannerContainer>
      {!loadingUpgrades && (
        <PriceDetails>
          {prices?.map((p) => (
            <div key={p.label}>
              <PriceLabel centered>
                <span>$</span>
                <h3 className="no-margin">{p.price}</h3>
              </PriceLabel>
              <p className="overline light-gray">{p.label}</p>
            </div>
          ))}
        </PriceDetails>
      )}
      <EstimateSummary prospect={prospect} />
    </>
  ) : (
    <Loader fullScreen={false} flex />
  )
}

export default EstimateData

const EstimateSummary = ({ prospect }: { prospect?: Prospect | null }) => {
  const estimate = prospect?.data?.estimate

  return (
    <SummaryContainer>
      <SummaryItem>
        <LayoutIcon />
        <span>{estimate?.layout}</span>
      </SummaryItem>
      <SummaryItem>
        <DimensionsIcon />
        <span>{formatDimensionFeetAndInches(estimate?.linearMillimeters)}</span>
      </SummaryItem>
      <SummaryItem>
        <CabinetHeightIcon />
        <span>{estimate?.cabinetHeight}</span>
      </SummaryItem>
      <SummaryItem>
        <StyleIcon />
        <span>{estimate?.style}</span>
      </SummaryItem>
      <SummaryItem>
        <UpperStorageIcon />
        <span>{estimate?.upperStorage}</span>
      </SummaryItem>
      <SummaryItem>
        {estimate?.integratedLighting ? (
          <IntegratedLightningIcon />
        ) : (
          <NoIntegratedLightningIcon />
        )}
        <span>{`${
          !estimate?.integratedLighting ? 'No ' : ''
        }Integrated Lighting`}</span>
      </SummaryItem>
    </SummaryContainer>
  )
}

const PlannerContainer = styled.div`
  margin: 0 auto;
  max-width: 400px;
`

const PriceDetails = styled.div`
  align-items: center;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 30px 0;
  text-align: center;

  div {
    flex: 1;

    h4 {
      margin-top: 5px;
    }

    h2 {
      margin: 0 auto;
    }
  }
`
