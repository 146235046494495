import React from 'react'

import { navigate } from 'gatsby'
import styled from 'styled-components'

import { useSelectionCenterVisit } from 'context/selection-centers/visit/provider'
import { SecondaryCard } from 'design-system/components/cards'
import { SectionDivider } from 'design-system/components/section-divider'
import { resourcesUrls } from 'views/utils'

export const SelectionCenterUsefulLinks = () => {
  const { project } = useSelectionCenterVisit()

  return (
    <Container>
      <SectionDivider title="Useful Links" />
      <div className="links">
        {project ? (
          <SecondaryCard
            icon="users"
            onClick={() => navigate('brief')}
            title="Project Brief"
          />
        ) : null}

        <SecondaryCard
          icon="oven"
          onClick={() => window.open(resourcesUrls.catalog)}
          title="Kitchen Catalog"
        />

        <SecondaryCard
          icon="book-heart"
          onClick={() => window.open(resourcesUrls.caseStudies)}
          title="Case Studies"
        />

        <SecondaryCard
          icon="images"
          onClick={() => window.open('https://formkitchens.com/projects')}
          title="Gallery"
        />

        <SecondaryCard
          icon="ballot-check"
          onClick={() => window.open('https://formkitchens.com/how-it-works')}
          title="How it works"
        />

        <SecondaryCard
          icon="sparkles"
          onClick={() => window.open(resourcesUrls.technicalGuide)}
          title="Technical Guide"
        />

        <SecondaryCard
          icon="circle-question"
          onClick={() => window.open('https://formkitchens.com/faq')}
          title="FAQs"
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;

  div.links {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }
`
