import React from 'react'

import styled from 'styled-components'

import { Colors } from 'styles/app/system'

type SectionDividerProps = {
  title: string
}

export const SectionDivider = ({ title }: SectionDividerProps) => {
  return (
    <Container>
      <p className="overline light-gray no-margin">{title}</p>
      <HorizontalLine />
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  column-gap: 16px;
`

const HorizontalLine = styled.div`
  border-top: 1px solid ${Colors.blue200};
  flex-grow: 1;
  height: 0;
`
