import React, { useEffect } from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import useAuth from 'context/auth/use'
import { notifyAppError } from 'context/notifications/trigger'
import { useSelectionCenters } from 'context/selection-centers/provider'

const SelectionCenterIndex = ({
  children,
}: RouteComponentProps & { children: React.ReactNode }) => {
  const { setSelectionCenter, selectionCenters, selectionCenter, loading } =
    useSelectionCenters()
  const { isAuthenticated, isSuperAdmin, isSelectionCenterManager, user } =
    useAuth()

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (!isAuthenticated) return
    const isAllowed = isSuperAdmin || isSelectionCenterManager
    if (!isAllowed) navigate('/')
  }, [isAuthenticated, isSuperAdmin, isSelectionCenterManager])

  useEffect(() => {
    const _selectionCenters = selectionCenters.filter((sc) =>
      sc.staff.map((s) => s.staffMember?.email).includes(user.email),
    )

    if (!_selectionCenters.length && !loading) {
      navigate('/admin')
      notifyAppError('Looks like you are not assigned to any selection enter.')
      return
    }

    if (!selectionCenter && !loading) {
      setSelectionCenter(_selectionCenters[0].identifier)
    }
  }, [
    selectionCenters,
    setSelectionCenter,
    user,
    isSuperAdmin,
    selectionCenter,
    loading,
  ])

  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')[0]
    const bodyElement = document.getElementsByTagName('body')[0]

    const handleResize = () => {
      if (window.innerWidth < 768) {
        bodyElement.style.setProperty(
          'height',
          `${window.innerHeight}px`,
          'important',
        )
        htmlElement.style.setProperty(
          'height',
          `${window.innerHeight}px`,
          'important',
        )
      } else {
        htmlElement.classList.remove('no-overflow')
        htmlElement.style.height = '100%'
        bodyElement.style.height = '100%'
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <>{children}</>
}

export default withAuthenticationRequired(SelectionCenterIndex)
