import React, { MutableRefObject, useEffect } from 'react'

import { useScrollRestoration } from 'gatsby'

import Navigation, { NavItem } from 'design-system/components/guide/navigation'
import { Spacer } from 'design-system/components/guide/utils'
import { useScrollspy } from 'hooks'

type SelectionCenterNavigationProps = {
  containerRef: React.RefObject<HTMLDivElement>
}

export const SelectionCenterNavigation = ({
  containerRef,
}: SelectionCenterNavigationProps) => {
  const scrollRestoration = useScrollRestoration('scroll-page')
  const activeId = useScrollspy(
    sectionsIds,
    scrollRestoration.ref as MutableRefObject<HTMLElement>,
  )

  useEffect(() => {
    if (scrollRestoration.ref?.current) {
      // Don't apply smooth scrolling when restoring scroll, only when navigation is clicked
      setTimeout(() => {
        if (scrollRestoration.ref.current)
          scrollRestoration.ref.current.style.scrollBehavior = 'smooth'
      }, 100)
    }
  }, [scrollRestoration])

  return (
    <>
      <Navigation
        containerRef={containerRef}
        items={sections}
        activeId={activeId}
        disableSticky
        disableMobileCheck
        disableFullHeight
      />
      <Spacer height={200} />
    </>
  )
}

const sections: NavItem[] = [
  {
    title: 'Features Wall',
    icon: 'cog',
    linkTo: 'features-wall',
  },
  {
    title: 'Finishes Wall',
    icon: 'bring-forward',
    linkTo: 'finishes-wall',
  },
  {
    title: 'Handles Island',
    icon: 'hand-holding',
    linkTo: 'handles-island',
  },
  {
    title: 'Next Steps',
    icon: 'info-circle',
    linkTo: 'next-steps',
  },
]

const sectionsIds = sections.map((s) => s.linkTo as string)
