import { useEffect, useState } from 'react'

import { getNameForProspect } from 'context/prospect/utils'
import useUserUniqueLazy from 'context/user/use-unique-lazy'
import { Meeting } from 'types/meeting'
import { Prospect } from 'types/prospect'
import { User } from 'types/user'
import { getNameForUser } from 'views/utils'

export const useMeetingUserOrProspect = (meeting?: Meeting | null) => {
  const [user, setUser] = useState<User | null>(null)
  const [prospect, setProspect] = useState<Prospect | null>(null)
  const { fetchUser } = useUserUniqueLazy()

  useEffect(() => {
    if (!meeting) return
    setProspect(meeting.prospect ?? null)
    if (meeting.project?.owners) {
      const projectUser = meeting.project.owners.find(
        (owner) =>
          owner.user?.email === meeting.metadata?.selectionCenter?.email,
      )?.user

      if (projectUser) {
        setUser(projectUser)
        return
      }
    }

    //* Try to find the user by email
    fetchUser({
      variables: {
        where: {
          email: meeting.metadata?.selectionCenter?.email,
        },
      },
      onCompleted: (data) => {
        setUser(data.user ?? null)
      },
      onError: () => {
        setUser(null)
      },
    })
  }, [meeting])

  return {
    clientEmail:
      user?.email ||
      prospect?.email ||
      meeting?.metadata?.selectionCenter?.email ||
      '',
    clientName: user
      ? getNameForUser(user)
      : prospect
      ? getNameForProspect(prospect)
      : `${meeting?.metadata.selectionCenter?.firstName} ${meeting?.metadata.selectionCenter?.lastName}`,
    isProspect: !user && !!prospect,
    prospect,
    user,
  }
}
